<template>
  <div>
    <el-form v-bind="$form.getFormProps($options, {}, $data)">
      <el-form-item :label="$tf('case_face_id')" prop="id" :rules="[{ trigger: 'change', validator: validateId }]">
        <el-input :placeholder="$tf('id')" v-model="id" style="width: 16rem; margin-top: 1rem" clearable></el-input>
      </el-form-item>

      <el-form-item :label="$tf('photo')" v-if="caseface">
        <object-thumbnail
          :url="caseface.thumbnail"
          :frame="caseface.source_photo"
          @preview="
            () =>
              $store.dispatch('showImage', {
                src: caseface.source_photo,
                box: $filters.getBorderBoxFromCaseFace(caseface)
              })
          "
        />
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import Component from 'vue-class-component';
import _ from '@/apps/common/lodash';
import { ObjectsType } from '@/store/objects/get.module';
import ObjectThumbnail from '../../../objects/thumbnail';

@Component({
  name: 'case-face-object-selector',
  components: { ObjectThumbnail },
  props: {
    objects: {
      type: String,
      default: ObjectsType.Faces
    },
    filter: {
      type: Object
    },
    state: {
      type: Object
    }
  }
})
export default class CaseFaceObjectsSelector extends Component {
  id = '';
  caseface = null;

  created() {
    this.validateId = _.debounce(this.validateId, 1000);
  }

  mounted() {
    this.applyLooksLike();
  }

  get looksLikeName() {
    return 'caseface';
  }

  applyLooksLike() {
    if (!this.filter.looks_like) return;
    const [looksLikeName, id] = this.filter.looks_like.split(':');
    this.id = id;
    this.load();
  }

  reset() {
    this.caseface = null;
    this.filter.looks_like = '';
  }

  load() {
    const id = this.id;
    if (!id) return;
    this.state.loading = true;
    return this.$store
      .dispatch(this.$store.state.case_faces.Action.Get, { id })
      .then((v) => {
        this.filter.looks_like = `${this.looksLikeName}:${id}`;
        this.caseface = v;
        return Promise.resolve(v);
      })
      .catch((e) => {
        this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e } }) });
        return Promise.reject(e);
      })
      .finally(() => {
        this.state.loading = false;
      });
  }

  validateId(rule, value, cb) {
    if (!value || value === this.caseface?.id) return cb();
    this.reset();
    this.load()
      .then(() => {
        cb();
      })
      .catch((e) => {
        cb(this.$tfo('caseface | {value} | not | found,,2', { value }));
      });
  }
}
</script>

<style lang="stylus"></style>
