<template>
  <div>
    <el-form v-bind="$form.getFormProps($options, {}, {})">
      <el-form-item :label="$tf('dossier_id')" prop="id">
        <el-input :placeholder="$tf('id')" v-model="id" style="width: 16rem; margin-top: 1rem" clearable></el-input>
      </el-form-item>

      <el-form-item :label="$tf('photos')">
        <template slot="label">
          <div>{{ $tf('photos') }}</div>
          <div v-if="id">
            <a @click="selectAllObjectsFaces">{{ $tf('select | all') }}</a>
            <a @click="unselectAllObjectsFaces">{{ $tf('unselect | all') }}</a>
          </div>
        </template>

        <objects-inline
          ref="dossierObjects"
          :showId="true"
          :editable="false"
          :selectable="true"
          :id="id"
          :object="objects"
          :selectedIds="objectIds"
          :loadDebounce="true"
          @loadError="loadErrorHandler"
          @selectedChange="selectedObjectsFacesChange"
        ></objects-inline>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import Component from 'vue-class-component';
import { ObjectsType, ObjectsTypeSingleForm } from '@/store/objects/get.module';
import { Watch } from 'vue-property-decorator';
import ObjectsInline from '../../../objects/inline';

@Component({
  name: 'DossierObjectsSelector',
  components: { ObjectsInline },
  props: {
    objects: {
      type: String,
      default: ObjectsType.Faces
    },
    filter: {
      type: Object
    },
    state: {
      type: Object
    }
  }
})
export default class DossierObjectsSelector extends Component {
  id = '';
  objectIds = [];

  mounted() {
    this.applyFilters();
  }

  get looksLikeName() {
    return `${ObjectsTypeSingleForm[this.objects]}object`;
  }

  applyFilters() {
    const looksLikeArray = Array.isArray(this.filter.looks_like) ? this.filter.looks_like : [this.filter.looks_like];
    this.id = this.filter.sourceDossierId;
    this.objectIds = looksLikeArray.map((v) => v && v.split(':')[1]).filter((v) => !!v);
  }

  @Watch('filter.objectsType')
  objectsTypeHandler(v, p) {
    this.reset();
  }

  @Watch('id')
  idHandler(v, p) {
    this.filter.sourceDossierId = v;
    this.objectIds = [];
  }

  @Watch('objectIds')
  objectIdsHandler(v, p) {
    let result = v && v.length ? v.map((objectId) => `${this.looksLikeName}:${objectId}`) : '';
    this.filter.looks_like = result;
  }

  getSourceFacesCount() {
    return (this.$refs.dossierObjects && this.$refs.dossierObjects.items.length) || 0;
  }

  selectAllObjectsFaces() {
    this.$refs.dossierObjects && this.$refs.dossierObjects.selectAll();
  }

  unselectAllObjectsFaces() {
    this.$refs.dossierObjects && this.$refs.dossierObjects.unselectAll();
  }

  loadErrorHandler(e) {
    this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
  }

  selectedObjectsFacesChange(ids) {
    this.objectIds = ids;
  }

  changeFace(face) {
    this.filter.looks_like = 'detection:' + face.id;
  }

  reset() {
    this.id = '';
    this.objectIds = [];
  }
}
</script>

<style lang="stylus"></style>
