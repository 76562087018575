<template>
  <div>
    <el-table :size="$vars.sizes.table || $vars.sizes.common" stripe :data="state.items" row-key="id" @row-click="rowClick" class="dossiers-table">
      <el-table-column prop="id" :label="$tf('id')" width="100"></el-table-column>
      <el-table-column prop="" :label="$tf('photo')" width="200">
        <template slot-scope="{ row }">
          <object-thumbnail :id="getMatchedObjectId(row)" :objects="objects" @preview="(v) => $store.dispatch('showImage', v)"></object-thumbnail>
        </template>
      </el-table-column>
      <el-table-column prop="name" :label="$tf('dossier')">
        <template slot-scope="{ row }">
          <router-link name="item-btn" :to="{ path: getDossierLink(row) }">
            <span>{{ row.name }}</span>
            <span v-if="!hasObjectsConfidence">({{ getConfidenceValue(row) | floatToPercent }})</span>
          </router-link>

          <confidence-label v-if="hasObjectsConfidence" :objects="objects" :value="getConfidenceValue(row)"></confidence-label>
          <br />
          {{ row.comment }}
          <dossier-lists-inline :ids="row.dossier_lists"></dossier-lists-inline>
          <br />

          <div v-if="state.filter.current.sourceType === 'event'">
            <a
              v-if="!addedObjects[row.id]"
              v-loading="uploading"
              name="upload-event-face-dossier-btn"
              @click.prevent.stop="() => addObjectToDossier(row.id)"
              class="link"
            >
              <i class="mar-left--1 el-icon-plus"></i> {{ $tfo(`add | event | to | dossier,,1`) }}
            </a>
            <div v-else>
              {{ $tf('event | was,,2 | added,,2') }}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="role" :label="$tf('was_updated,, 1')" width="200">
        <template slot-scope="{ row }">
          {{ $filters.formatDate(row.modified_date) }}
          <br />
          {{ $filters.formatTime(row.modified_date) }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import { ObjectsType, ObjectsTypeSingleForm } from '@/store/objects/get.module';
import ObjectThumbnail from '../../../objects/thumbnail';
import DossierListsInline from '../../../dossier-lists/inline';
import ConfidenceLabel from '../../../common/confidence.label';

@Component({
  components: { ConfidenceLabel, DossierListsInline, ObjectThumbnail },
  props: {
    state: {
      type: Object,
      required: true
    },
    objects: {
      type: String,
      default: ObjectsType.Faces
    }
  }
})
export default class DossiersSearchResults extends Vue {
  addedObjects = [];
  uploading = false;

  addObjectToDossier(dossierId) {
    const eventId = this.state.filter.current.looks_like?.split(':')[1];
    if (!eventId) return;
    this.uploading = true;
    this.$store
      .dispatch('uploadObject', {
        create_from: `${ObjectsTypeSingleForm[this.objects]}event:${eventId}`,
        dossier: dossierId,
        objects: this.objects
      })
      .then((v) => {
        this.addedObjects[dossierId] = true;
        this.$notify({ type: 'success', message: this.$tf(['action', 'success']) });
      })
      .catch((e) => {
        this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
      })
      .finally(() => {
        this.uploading = false;
      });
  }

  getDossierLink(item) {
    return '/dossiers/' + encodeURIComponent(item.id) + '/';
  }

  rowClick(v) {
    this.$router.push({ path: this.getDossierLink(v) });
  }

  hasObjectsConfidence() {
    return this.$store.getters.hasObjectsConfidence(this.objects || 'faces');
  }

  getMatchedObjectId(row) {
    return row.looks_like?.matched_object;
  }
  getConfidenceValue(row) {
    return row.looks_like?.confidence;
  }
}
</script>
